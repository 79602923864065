@import "../common/variable";
@import "../common/mixins";
@import "../components/confirm-popup";
@import "../components/button";

.badge{
   padding: 2px 10px;
   border-radius: 3px;
   margin: 2px;
   display: inline-block;
}
.badge-primary{
    background-color: $primary-color;
    color: white;
}

.invalid-records-table {
  tr {
    th:first-child,
    td:first-child {
      text-align: left;
      padding-left: 35px;
      width: 15%;
      @media screen and (max-width: $screen-lg) {
        padding-left: 15px;
      }

      @media screen and (max-width: $screen-sm) {
        padding-left: 8px;
      }
    }

    th:last-child,
    td:last-child {
      width: 40%;
      text-align: center;
      padding-left: 20px;
      text-align: left;
      margin-bottom: 0;

      ol,li{
        margin-bottom: 0;
      }
    }

    th:nth-child(2),
    td:nth-child(2) {
      text-align: center;
      word-break: break-word;
      width: 20%;
    }

    th:nth-child(3),
    td:nth-child(3) {
      text-align: center;
      word-break: break-word;
      width: 10%;
    }

    th:nth-child(4),
    td:nth-child(4) {
      text-align: center;
      word-break: break-word;
      width: 15%;
    }
  }
  .no-data-found{
    color: #0069a7;
  }
}