@import "../common/mixins";
@import "../common/variable";
@import "./chat";

.chat-popup-container {
    position: sticky;
    bottom: 0px;
    height: 0;
    z-index: 3;

    @media screen and (max-width: $screen-xs) {
        z-index: 4;
    }

    .btn-chat {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        display: grid;
        place-content: center;
        background-image: linear-gradient(146deg, $tag-bg, $primary-color);
        color: $white-color;
        box-shadow: $shadow-lg;
        position: absolute;
        bottom: 30px;
        right: 30px;
        font-size: 14px;
        
        i {
            font-size: 22px;
        }
        
        @media screen and (max-width: $screen-xs) {
            font-size: 12px;
            height: 40px;
            width: 40px;
            bottom: 15px;
            right: 15px;
        }

        .unread-count-badge {
            position: absolute;
            right: -3px;
            top: 0px;
            height: 20px;
            width: 20px;
            background-color: $danger-color;
            color: $white-color;
            border-radius: 50%;
            line-height: 19px;
            font-size: 12px;
            text-align: center;
            font-weight: 600;
            box-shadow: $shadow;

            &.hide {
                display: none;
            }
        }
    }

    .chat-popup {
        display: flex;
        flex-direction: column;
        width: 380px;
        height: 600px;
        max-height: 75vh;
        border-radius: 10px;
        position: absolute;
        bottom: 45px;
        right: 45px;
        overflow: hidden;
        background-color: $white-color;
        box-shadow: $shadow-lg;

        @media screen and (max-width: $screen-xs) {
            width: 100%;
            height: calc(100vh - 80px);
            bottom: 0;
            right: 0;
            border-radius: 0;
            max-height: unset;
            position: fixed;
        }

        .chat-popup-header {
            display: flex;
            align-items: center;
            padding: 20px;
            color: $white-color;
            background: linear-gradient(106deg, $tag-bg, $primary-color);
            justify-content: space-between;

            @media screen and (max-width: $screen-xs) {
                padding: 10px;
            }

            button {
                color: $white-color;
            }

            .chat-user {
                display: flex;
                align-items: center;

                .chat-user-pic {
                    height: 50px;
                    width: 50px;
                    border-radius: 50%;
                    margin-right: 15px;
                    position: relative;

                    @media screen and (max-width: $screen-xs) {
                        height: 40px;
                        width: 40px;
                    }

                    img {
                        border-radius: 50%;
                        height: 100%;
                        width: 100%;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }

                .chat-user-detail {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        .chat-popup-body {
            flex-grow: 1;
            padding: 20px;
            overflow: auto;

            .chat-body-messages {
                display: flex;
                flex-direction: column;
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    max-width: 90%;
                    list-style: none;
                    display: flex;
                    align-items: flex-start;
                    color: $body-text-color;
                    padding: 0;

                    &.msg-date {
                        max-width: 100%;
                        position: sticky;
                        top: -10px;
                        width: 100%;
                        text-align: center;
                        display: block;
                        // background-color: $white-color;
                        z-index: 1;
                        color: $gray-3;
                        font-size: 80%;

                        span {
                            display: inline-block;
                            padding: 0px 10px;
                            background-color: #efefef;
                            border-radius: 20px;
                        }
                    }

                    &:before {
                        display: none;
                    }

                    .message-user-pic {
                        height: 30px;
                        width: 30px;
                        border-radius: 50%;
                        min-width: 30px;
                        margin-right: 10px;
                        border: 1px solid $border-color-light;

                        img {
                            border-radius: 50%;
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                        }
                    }

                    .message-content-wrapper {
                        margin-bottom: 10px;
                        display: flex;
                        flex-direction: column;

                        & > small {
                            color: $gray-1;
                            font-size: 12px;
                        }

                        .chat-message-time {
                            margin-left: 5px;
                        }

                        .chat-message-content {
                            background-color: $border-color-light;
                            border-radius: 0px 10px 10px 10px;
                            padding: 10px 15px;
                            font-size: 14px;
                            position: relative;
                        }
                    }

                    &.self {
                        margin-left: auto;

                        .chat-message-content {
                            background-image: linear-gradient(146deg, $tag-bg, $primary-color);
                            color: $white-color;
                            border-radius: 10px 10px 0px 10px;
                        }

                        .chat-message-time {
                            margin-left: auto;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }

        .chat-popup-footer {
            padding: 20px;
            background-color: $white-color;
            box-shadow: $shadow;

            @media screen and (max-width: $screen-xs) {
                padding: 10px;
            }

            .chat-popup-form {
                background-color: $border-color-light;
                border-radius: 10px;
                display: flex;
                align-items: center;
                padding: 15px 5px 14px 20px;

                @media screen and (max-width: $screen-xs) {
                    padding: 10px 5px 9px 15px;
                }

                input {
                    background-color: transparent;
                    flex-grow: 1;
                    border: none;
                    font-size: 14px;
                }

                button {
                    color: $primary-color;
                    font-size: 26px;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}