@import "../common/variable";
@import "../common/mixins";
@import "../components/confirm-popup";
@import "../components/button";

.content{
  position: relative;
  .total-count{
    position: absolute;
    bottom: 42px;
    color: $primary-color;
    font-weight: 600;
  }
}

.notification-link a {
  text-decoration: none;
  cursor: pointer;
  margin: 0 1rem;
}
   
.table-wrapper {
  overflow: auto;
  max-width: 100%;
  border-radius: 10px 10px 0px 0px;
}

.employee-table {
  tr {
    th:first-child,
    td:first-child {
      text-align: left;
      padding-left: 30px;
      width: 20%;
      @media screen and (max-width: $screen-lg) {
        padding-left: 15px;
      }

      @media screen and (max-width: $screen-sm) {
        padding-left: 8px;
      }
    }

    th:last-child,
    td:last-child {
      width: 6%;
      text-align: center;
      @media screen and (min-width: $screen-xl) {
        padding-left: 20px;
        padding-right: 30px;
      }
    }

    th:nth-child(2),
    td:nth-child(2) {
      text-align: center;
      word-break: break-word;
      width: 25%;
    }

    th:nth-child(3),
    td:nth-child(3) {
      text-align: center;
      word-break: break-word;
      width: 15%;
    }

    th:nth-child(4),
    td:nth-child(4) {
      text-align: center;
      word-break: break-word;
      width: 10%;
    }

    th:nth-child(5),
    td:nth-child(5) {
      text-align: center;
      width: 10%;
    }

    th:nth-child(6),
    td:nth-child(6),
    th:nth-child(8),
    td:nth-child(8),
    th:nth-child(7),
    td:nth-child(7) {
      width: 7%;
    }

    td.checked{
      text-align: center;
    }
  }
}



.table-hover {
  tr:hover {
    background-color: $table-body-color;
  }
}

.pagination {
  .previous,
  .next {
    a {
      color: #434345;
    }

    &.disabled {
      a {
        color: #ababab;
      }
    }
  }
}

.title-with-btn{
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.upload-file{
  button{
    padding: 10px;
    border-radius: 5px;
    background-color: #0069a7;
    color: white;
    font-weight: 600;
    font-size: 16px;
   
    &:hover {
      background-color: #005387; 
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .table-wrapper {
    border-radius: 0;
    overflow: auto;
  }
}

.form-filter-group {
  display: flex;
  align-items: center;

  .status {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 300px;
    position: relative;

    span {
      width: 35%;
    }
  }

  .search {
    display: flex;
    position: relative;
    align-items: center;
    max-width: 280px;
    width: 100%;

    @media screen and (max-width: $screen-sm) {
      width: 50%;
    }
  }

  .icon-search {
    position: absolute;
    z-index: 999;
    width: 20px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: $screen-sm) {
  .form-filter-group {
    align-items: center;

    .status {
      display: flex;
      align-items: center;
      width: 50%;
    }

    .dropdown {
      width: 90%;
      margin-left: 10px;
    }

    .search {
      width: 50%;
      display: flex;
      position: relative;
      align-items: center;
    }

    .form-control {
      border: 1px solid $border-color;
      @include border-radius(5px);
      background-color: $white-color;
      flex-grow: 1;
      padding: 7.5px 30px 7.5px 10px;
      width: 100%;
    }

    .icon-search {
      width: 20px;
      right: 5px;
    }
  }
}
@media screen and (max-width: $screen-lg) {
  .page-header {
    flex-wrap: wrap;
  }
  .page .page-header-left {
    width: 100%;
    margin-bottom: 20px;
  }
  .form-filter-group {
    flex-wrap: wrap;
    width: 100%;
  }
  
  .form-filter-group .search {
    width: 100%;
  }
  .form-filter-group .status {
    width: 100%;
    padding-top: 10px;
  }
  .form-filter-group .dropdown {
    padding-left: 0;
    width: 100%;
    margin-left: 0;
  }
}

@media screen and (max-width: $screen-xs) {
  .form-filter-group {
    flex-wrap: wrap;
  }
  .form-filter-group .search {
    width: 100%;
  }
  .form-filter-group .status {
    width: 100%;
    padding-top: 10px;
  }
  .form-filter-group .dropdown {
    padding-left: 0;
    width: 100%;
    margin-left: 0;
  }
}

@media screen and (max-width: $screen-md) {
  .popup .content {
    width: 680px;
    .main-content-top{
      width: 580px;
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .popup .content {
    .main-content-top{
      .browse-file-btn{
        width: 130px;
      }
    }
    .main-content-bottom {
      .download-file{
        text-align: center;
      }
    }
    .footer-content button{
      padding: 15px;
    }
  }
}

@media screen and (max-width: $screen-xs) {
  .popup .content {
    width: 350px;
    .main-content {
      padding: 20px 0;
    }
    .main-content-top{
      width: 250px;
      padding: 20px 10px;

      .popup .main-content .main-content-top .file-heading{
        font-size: 12px;
      }
      .file-text,
      .selected-file-name
      {
        font-size: 12px;
        line-height: 16px;
      }
      .browse-file-btn{
        font-size: 12px;
        height: 35px;
        padding: 5px 5px;
      }
    }
     .main-content-bottom {
        padding-top: 18px;
        p{
            font-size: 12px;
            line-height: 16px;
        }
        .download-file button{
          font-size: 12px;
        }
      }
    .popup .heading {
      font-size: 18px;
      margin-bottom: 0px;
    }
    .footer-content button{
      font-size: 12px;
      padding: 10px;
    }
  }
}

.icon-eye {
  cursor: pointer;
}

.active {
  color: #4fc886;
}

.in-active {
  color: #ed9255;
}

.icon-search {
  cursor: pointer;
}
.emp-card-wrapper{
  @include flexbox;
  gap: 28px;
  margin: 30px 0 40px;
  flex-wrap: wrap;

  @media screen and (max-width: $screen-lg) {
    gap: 28px;
  }

  @media screen and (max-width: $screen-md) {
    gap: 20px;
  }
  
  .emp-card-container{
    width: calc(33% - 18px);
    background-color: $white-color;
    border-radius: 10px;
    box-shadow: 2px 2px 5px $border-color;

    @media screen and (max-width: $screen-lg) {
      width: calc(50% - 20px);
    }
    
    @media screen and (max-width: $screen-md) {
      width: 100%;
      flex-wrap: wrap;
    }

    .emp-card{
      padding: 30px;
      .card-title{
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 0;
      }
      .emp-card-details{
        @include flexbox;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        .emp-count{
          color: $primary-color;
          font-size: 50px;
          line-height: 75px;
          margin-bottom: 0;
          font-weight: 600;
        }
      }
      
      .wrapper-icon{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: #e3f4ff;
        display: flex;
        justify-content: center;
        align-items: center;
        i{
          font-size: 35px;
          color: $primary-color;
        }
      }
    }
  }
}