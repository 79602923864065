@import "../common/variable";
@import "../common/mixins";

.content {
  .content-box {
    min-height: 600px;
    padding: 35px 40px;
    background-color: $white-color;
    border: 1px solid $border-color;
    border-radius: 10px;
    position: relative;

    @media screen and (max-width: $screen-md) {
      padding: 15px 20px;
    }
    @media screen and (max-width: $screen-sm) {
      min-height: 0px;
    }
    .row {
      .mt-2 {
        margin-top: 10px;
      }
    }
    .mt-3 {
      margin-top: 0px;
    }

    .mb-3 {
      margin-bottom: 23px;
    }
    .fullname-spacing-left {
      .form-control.no-icon {
        @media screen and (max-width: $screen-md) {
          padding-left: 15px;
        }
      }
    }

    .mobile-spacing-left {
      .form-control.no-icon {
        @media screen and (max-width: $screen-md) {
          padding-left: 35px;
        }
      }
    }
    .form-group {
      margin-bottom: 15px;
      .mobile {
        span {
          @media screen and (max-width: $screen-md) {
            margin-left: 15px;
            justify-content: flex-start;
          }
        }
      }
    }
    .content-title {
      color: $primary-color;
      font-weight: 500;
      padding-bottom: 20px;
      border-bottom: 1px solid $border-color;
      margin-bottom: 30px;
    }

    .page-loader {
      position: absolute;
      height: calc(100% + 10px);
      width: calc(100% + 10px);
      top: -5px;
      left: -5px;
    }

    .member-card {
      .member-sn {
        @media screen and (max-width: 768px) {
          margin-bottom: 10px;
        }
      }
    }
    .member-detail {
      @media screen and (max-width: $screen-sm) {
        text-align: center;
        padding-left: 0;
      }
      span {
        @media screen and (max-width: $screen-sm) {
          margin: 0 5px;
          padding-right: 30px;
        }

        @media screen and (max-width: $screen-xxs) {
          display: block;
          padding-right: 0;
          margin-bottom: 10px;
        }
      }

      a {
        @media screen and (max-width: $screen-sm) {
          position: static;
          margin: 0 5px;
        }
        @media screen and (max-width: $screen-xxs) {
          display: block;

          padding-right: 0;
          padding-left: 0;
        }
      }
    }
  }
}

.custome-file {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  height: 120px;
  width: 120px;

  .custome-file-image {
    border-radius: 50%;
    overflow: hidden;
    background-color: $gray-2;
    height: 100%;
    width: 100%;
    border: 1px solid $border-color;

    & > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .custome-file-input {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 40px;
    width: 40px;
    color: $white-color;
    background-color: $primary-color;
    border-radius: 50%;
    overflow: hidden;
    display: grid;
    place-content: center;
    font-size: 20px;
    cursor: pointer;

    &.loading {
      &:before {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: grid;
        place-content: center;
        content: "";
        background-color: rgba($white-color, 0.5);
        border-radius: 50%;
        pointer-events: none;
      }
    }

    input[type="file"] {
      opacity: 0;
      // visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      cursor: pointer;
    }

    & > i {
      pointer-events: none;
      font-size: 17px;
      line-height: 1;
    }
  }
}

.table-wrapper {
  overflow: auto;
  max-width: 100%;
  border-radius: 10px;
  overflow: hidden;

  .plan-table {
    // border-collapse: separate;
    // border-spacing: 5px;
    width: 100%;
    border: 0;

    .plan-table-header {
      th {
        padding-top: 28px;
        padding-bottom: 28px;
      }
    }

    tr {
      th {
        border: 5px solid $white-color;
        border-top: none;
        border-bottom: none;
        width: 33.33%;

        &:first-child {
          border-left: 0;
        }

        &:last-child {
          border-right: 0;
        }
      }

      td {
        border-left: 5px solid $white-color;
        border-right: 5px solid $white-color;
        width: 33.33%;
      }

      &:last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    tbody {
      tr {
        td {
          background-color: $table-body-color;
          text-align: center;

          &:first-child {
            text-align: left;
            background-color: $border-color-light;
            border-left: 0;
          }

          &:last-child {
            border-right: 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: $screen-sm) {
    .plan-table-wrapper {
      border-radius: 0;
      overflow: auto;

      .plan-table {
        tbody,
        thead {
          th,
          td {
            // white-space: nowrap;
            white-space: normal;
          }
        }

        margin-bottom: 15px;
      }
    }
  }
}

.profile-otp {
  .form-group {
    .verification-digits {
      justify-content: flex-start;
      margin-bottom: 0;

      & > div:first-child {
        .otp-digit {
          margin-left: 0;
        }
      }

      & > div:last-child {
        .otp-digit {
          margin-right: 0;
        }
      }
    }
  }
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  @media screen and (max-width: $screen-xl) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: $screen-md) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.spouse-detail {
  display: flex;
  align-items: center;
  width: 100%;
  .spouse-detail-left {
    flex-grow: 1;
    margin-right: 10px;
    .spouse-name {
      font-weight: 500;
      margin-bottom: 5px;
      line-height: 1;
      & + span {
        line-height: 1;
        font-size: 14px;
      }
    }
  }
  .spouse-sn {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: $accent-color;
    color: $primary-color;
    display: grid;
    place-content: center;
  }
}
