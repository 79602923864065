@import "../common/variable";
@import "../common/mixins";

.site-header {
    box-shadow: $shadow;
    background-color: $white-color;
    z-index: 3;
    position: sticky;
    top: 0;

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: $screen-xs) {
            flex-wrap: wrap;
        }

        .site-logo {
            max-width: 171px;
        }

        & > form {
            flex-grow: 1;
            padding: 0 15px;
            max-width: 600px;

            @media screen and (max-width: $screen-xs) {
                order: 4;
                width: 100%;
                padding: 0;
                margin-bottom: 15px;
            }

            .header-search {
                .form-group {
                    margin-bottom: 0;
                }
            }
        }

        .header-profile {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            text-align: right;
            padding: 30px 0;
            position: relative;
            cursor: pointer;

            @media screen and (max-width: $screen-xs) {
                padding: 15px 0;
            }

            .profile-user-name {
                margin-right: 10px;

                @media screen and (max-width: $screen-sm) {
                    display: none;
                }

                span {
                    font-size: 12px;
                    line-height: 18px;
                    color: $gray-1;
                }

                .user-name {}
            }

            .profile-user-pic {
                height: 50px;
                width: 50px;
                border-radius: 50%;
                overflow: hidden;
                background-color: $gray-2;
                object-fit: cover;
                border: 1px solid $border-color;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            .user-menu {
                position: absolute;
                top: 100%;
                right: 0;
                background-color: $white-color;
                box-shadow: $shadow;
                border-radius: 5px;
                transform: translateY(-30px);
                max-height: calc(100vh - 220px);
                overflow: auto;
                opacity: 0;
                visibility: hidden;
                @include transition(0.4s);

                ul {
                    padding: 20px;
                    margin-bottom: 0;

                    li {
                        display: flex;
                        align-items: center;
                        padding: 0;
                        cursor: pointer;

                        a {
                            text-decoration: none;
                            color: $gray-4;
                            display: flex;
                            align-items: center;
                            width: 100%;
                            white-space: nowrap;
                        }

                        &:hover {
                            opacity: 0.8;
                        }

                        &::marker, &::before {
                            display: none;
                        }

                        & + li {
                            margin-top: 20px;
                            padding-top: 20px;
                            border-top: 1px solid $border-color-light;
                        }

                        i {
                            font-size: 22px;
                            width: 22px;
                            margin-right: 17px;
                        }
                    }
                }
            }

            &.active {
                .user-menu {
                    transform: translateY(0);
                    opacity: 1;
                    visibility: visible;
                    @include transition(0.4s);
                }
            }
        }
    }

    .header-right{
        display: flex;
        gap: 20px;
        align-items: center;

        .notification-link {
            display: inline-block;
            height: 50px;
            width: 50px;
            border-radius: 50%;
            overflow: hidden;
            background-color: $gray-2;
            object-fit: cover;
            border: 1px solid $border-color;
            cursor: pointer;

            &:hover {
                box-shadow: 0px 1px 10px #ccc;
            }

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }

}