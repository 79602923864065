@import "../common/variable";
@import "../common/mixins";

.shadow-card {
    display: flex;
    flex-flow: column;
    box-shadow: $shadow;
    padding: 1rem;
}

.home-card {
    background-color: $accent-color;
    padding: 60px;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 60% 40%;
    background-repeat: no-repeat;
    background-size: cover;
    color: $white-color;
    z-index: 1;
    overflow: hidden;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgb(0 0 0 / 30%);
        z-index: -1;
    }

    @media screen and (max-width: $screen-lg) {
        grid-template-columns: 1fr;
    }

    @media screen and (max-width: $screen-md) {
        padding: 30px;
    }
}

.image-box {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    z-index: 1;
    min-height: 300px;
    display: flex;
    align-items: center;

    &.main-banner {

        // margin-top: 30px;
        .box-content {
            width: 40%;

            @media screen and (max-width: $screen-lg) {
                width: 75%;
            }

            @media screen and (max-width: $screen-md) {
                width: 100%;
            }
        }
    }

    .box-content {
        padding: 60px;
        color: #fff;

        @media screen and (max-width: $screen-md) {
            padding: 30px;
        }

        .box-title {
            font-weight: 500;
            margin-bottom: 10px;
        }

        .box-text {
            font-size: 36px;
            line-height: 52px;
            margin-bottom: 30px;

            @media screen and (max-width: $screen-md) {
                font-size: 26px;
                line-height: 32px;
            }
        }

        .box-price {
            position: absolute;
            right: 60px;
            bottom: 40px;
            font-size: 50px;
            line-height: 1;

            small {
                font-size: 50%;
            }

            @media screen and (max-width: $screen-md) {
                right: 30px;
                bottom: 30px;
                font-size: 28px;
            }
        }
    }

    &:not(.main-banner) {
        height: 100%;
        display: flex;
        align-items: center;

        .box-bg {
            &:before {
                position: absolute;
                content: "";
                height: 100%;
                width: 100%;
                left: 0;
                top: 0;
                background-color: rgba($black-color, 0.3);
                z-index: -1;
            }
        }

        .box-content {
            width: 100%;
            padding: 55px 60px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .btn {
                margin-top: auto;
            }

            .box-title {
                font-size: 36px;
                line-height: 54px;
            }

            .box-text {
                font-size: 24px;
                line-height: 36px;
            }

            @media screen and (max-width: $screen-md) {
                width: 100%;
                padding: 30px;

                .box-title {
                    font-size: 26px;
                    line-height: 42px;
                }

                .box-text {
                    font-size: 18px;
                    line-height: 30px;
                }
            }
        }
    }

    .box-bg {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-position: left center;
        background-size: cover;
        z-index: -1;
    }
}

.auth-card {
    width: 100%;
    max-width: 540px;
    background-color: rgba($white-color, 0.6);
    box-shadow: $shadow-lg;
    border-radius: 10px;
    padding: 80px;

    @media screen and (max-width: $screen-lg) {
        padding: 50px;
    }

    @media screen and (max-width: $screen-md) {
        padding: 30px;
    }

    @media screen and (max-width: $screen-sm) {
        max-width: 100%;
    }
}

.member-card {
    padding: 30px;
    // margin-bottom: 30px;
    background-color: $white-color;
    border: 1px solid $border-color;
    border-radius: 5px;
    display: flex;
    align-items: center;

    @media screen and (max-width: $screen-sm) {
        padding: 15px;
    }

    &:hover {
        box-shadow: $shadow;
    }

    .member-sn {
        height: 120px;
        width: 120px;
        font-size: 36px;
        line-height: 1;
        font-weight: 600;
        border-radius: 50%;
        text-transform: uppercase;
        color: $primary-color;
        background-color: $info-color;
        display: grid;
        place-content: center;
        overflow: hidden;

        & > img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        @media screen and (max-width: $screen-sm) {
            height: 60px;
            width: 60px;
            font-size: 24px;
        }
    }

    .member-detail {
        padding-left: 30px;
        position: relative;
        flex-grow: 1;

        & > .btn {
            position: absolute;
            bottom: 0;
            right: 0;
        }

        @media screen and (max-width: $screen-sm) {
            padding-left: 15px;
        }

        .member-name {
            @include font-xxs;
            font-weight: 500;
            color: $gray-3;
            margin-bottom: 10px;
        }

        .member-detail {
            margin: 0 0 12px;
            padding: 0;

            li {
                padding: 0;
                margin-bottom: 0;

                &::before {
                    display: none;
                }
            }
        }
    }
}

.doctor-card {
    padding: 30px;
    background-color: $white-color;
    border: 1px solid $border-color;
    border-radius: 5px;
    display: flex;

    &:hover {
        box-shadow: $shadow;
    }

    .doctor-image {
        max-height: 120px;
        width: 100%;
        max-width: 120px;
        font-size: 36px;
        line-height: 1;
        font-weight: 600;
        border-radius: 50%;
        text-transform: uppercase;
        color: $primary-color;
        background-color: $info-color;
        display: grid;
        place-content: center;
        overflow: hidden;
        flex-grow: 1;

        @media screen and (max-width: $screen-xxl) {
            max-width: 100px;
            max-height: 100px;
        }

        & > img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .doctor-location > a {
        color: $white-color;
        height: 40px;
        width: 40px;
        border-radius: 5px;
        background-color: $primary-color;
        text-decoration: none;
        line-height: 40px;
        text-align: center;
        display: inline-block;
    }

    .doctor-card-detail {
        padding-left: 20px;
        padding-right: 15px;
        position: relative;
        flex-grow: 1;

        .network-tag {
            position: absolute;
            top: -30px;
            font-size: 14px;
            font-weight: 500;
            padding: 0 10px;
            background-color: $tag-bg;
            color: $white-color
        }

        .doctor-card-header {
            h5 {
                @include font-xxs;
                font-weight: 500;
                color: $primary-color;
                margin-bottom: 10px;

                span {
                    color: $body-text-color;
                    display: block;
                    @include body-font;
                    font-weight: 500;
                }
            }
        }

        .doctor-speciality {
            color: $secondary-color;
            margin-bottom: 5px;
        }

        address {
            max-width: 90%;
        }
    }
    @media screen and (max-width: $screen-sm) {
        flex-wrap: wrap;
        padding: 15px;
        .doctor-image {
            margin: 0 auto 30px;
        }
        .doctor-card-detail {
            padding-left: 0;
            .network-tag {
                position: relative;
                top: unset;
            }
        }
    }
}

.user-card {
    display: flex;
    align-items: flex-end;

    @media screen and (max-width: $screen-sm) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .user-image {
        height: 240px;
        width: 240px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 40px;

        @media screen and (max-width: $screen-sm) {
            margin-right: 0;
            margin-bottom: 15px;
            height: 180px;
            width: 180px;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .user-detail {}
}