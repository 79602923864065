@import "../common/mixins";
@import "../common/variable";

.chat {
    display: grid;
    width: 100%;
    grid-template-columns: 20% 1fr;
    background-color: $white-color;
    border-radius: 10px;
    height: 65vh;

    @media screen and (max-width: $screen-xxl) {
        grid-template-columns: 30% 1fr;
    }

    @media screen and (max-width: $screen-md) {
        grid-template-columns: 1fr;
        position: relative;
    }

    // @media screen and (max-width: $screen-md) {
    //     height: auto;
    // }
    .chat-sidebar {
        border-right: 1px solid $border-color-light;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: $screen-md) {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #fff;
            z-index: 1;
            opacity: 1;
            visibility: visible;
            left: 0;
            transition-duration: 0.3s;

            &.hide {
                opacity: 0;
                visibility: hidden;
                left: -100%;
                transition-duration: 0.3s;
            }
        }

        .chat-sidebar-header {
            padding: 25px 20px;
            border-bottom: 1px solid $border-color-light;

            @media screen and (max-width: $screen-lg) {
                padding: 15px 10px;
            }

            .chat-user-search {
                position: relative;

                input {
                    border: 1px solid $border-color;
                    @include border-radius(5px);
                    background-color: $white-color;
                    padding: 12px 15px;
                    width: 100%;
                }

                i {
                    position: absolute;
                    right: 15px;
                    top: 12px;
                    font-size: 20px;
                }
            }
        }

        .chat-user-list {
            flex-grow: 1;
            overflow: auto;
            padding: 5px 20px;
            max-height: calc(65vh - 110px);

            @media screen and (max-width: $screen-lg) {
                padding: 5px 10px;
                max-height: calc(65vh - 80px);
            }

            .chat-user {
                display: flex;
                align-items: center;
                text-decoration: none;
                padding: 20px 10px;
                border-bottom: 1px solid $border-color-light;
                background-color: $white-color;

                @media screen and (max-width: $screen-lg) {
                    padding: 10px 8px;
                }

                &.active, &:hover {
                    background-color: $border-color-light;
                }

                .user-avtar {
                    height: 45px;
                    width: 45px;
                    min-width: 45px;
                    border-radius: 50%;
                    position: relative;
                    border: 1px solid $border-color-light;

                    img {
                        border-radius: 50%;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }

                .user-detail {
                    padding: 0 10px;
                    flex-grow: 1;

                    .user-name {
                        font-weight: 500;
                    }

                    .user-msg {
                        display: inline-block;
                        max-width: 175px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        @media screen and (max-width: $screen-lg) {
                            max-width: 120px;
                        }
                    }
                }

                .user-message-count {
                    height: 40px;
                    width: 40px;
                    border-radius: 5px;
                    background-color: $tag-bg;
                    color: $white-color;
                    font-size: 14px;
                    display: grid;
                    place-content: center;
                }

                color: $gray-4;
            }
        }
    }

    .chat-listing {
        position: relative;

        .default-chat {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            padding: 20px;

            img {
                // max-width: 500px;
                max-height: 90%;
            }
        }

        .chat-window {
            display: flex;
            flex-direction: column;
            height: 100%;

            .chat-window-header {
                display: flex;
                justify-content: space-between;
                padding: 25px 30px;
                align-items: center;
                border-bottom: 1px solid #f3f3f3;

                @media screen and (max-width: $screen-lg) {
                    padding: 15px 20px;
                }

                .chat-back-btn {
                    display: none;

                    @media screen and (max-width: $screen-md) {
                        display: inline-block;
                        font-size: 20px;
                        margin-right: 10px;
                    }
                }

                .chat-user {
                    flex-grow: 1;
                    align-items: center;
                    display: flex;

                    .user-profile-pic {
                        height: 50px;
                        width: 50px;
                        min-width: 50px;
                        border-radius: 50%;
                        margin-right: 10px;
                        position: relative;
                        border: 1px solid $border-color-light;

                        img {
                            border-radius: 50%;
                            object-fit: cover;
                            height: 100%;
                            width: 100%;
                        }
                    }

                    .chat-user-name {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }

            .chat-window-listing {
                padding: 30px;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                overflow: auto;
                max-height: calc(65vh - 180px);

                @media screen and (max-width: $screen-lg) {
                    max-height: calc(65vh - 160px);
                }

                .chat-date {
                    text-align: center;
                    position: sticky;
                    top: 0px;
                    margin-bottom: 10px;

                    span {
                        background-color: $border-color-light;
                        padding: 5px 10px;
                        border-radius: 5px;
                        display: inline-block;
                    }
                }

                .chat-message-listing {
                    flex-grow: 1;
                    max-height: 100%;
                    overflow: auto;
                    display: flex;
                    flex-direction: column;

                    .chat-message-item {
                        display: flex;
                        align-items: flex-start;
                        margin-bottom: 20px;
                        max-width: 80%;

                        .chat-message-profile {
                            height: 40px;
                            width: 40px;
                            min-width: 40px;
                            margin-right: 10px;
                            border-radius: 50%;
                            border: 1px solid $border-color-light;

                            img {
                                height: 100%;
                                width: 100%;
                                border-radius: 50%;
                                object-fit: cover;
                            }
                        }

                        .chat-message {
                            .chat-message-detail {
                                display: flex;
                                align-items: center;
                                font-weight: 500;

                                .chat-message-time {
                                    margin-left: 10px;
                                    color: #999;
                                    font-weight: 400;
                                    font-size: 14px;
                                }
                            }

                            .chat-message-content {
                                font-size: 15px;
                                padding: 15px;
                                background-color: $border-color-light;
                                border-radius: 10px 10px 10px 0;
                                max-width: 100%;
                                word-wrap: anywhere;
                            }
                        }

                        &.self {
                            margin-left: auto;

                            .chat-message {
                                .chat-message-detail {
                                    .chat-message-time {
                                        margin-left: auto;
                                    }
                                }
                            }

                            .chat-message-content {
                                border-radius: 10px 10px 0 10px;
                                background-color: $tag-bg;
                                color: $white-color;
                            }
                        }
                    }
                }
            }

            .chat-window-footer {
                display: flex;
                align-items: center;
                padding: 15px 30px;

                @media screen and (max-width: $screen-lg) {
                    padding: 10px 20px;
                }

                @media screen and (max-width: $screen-sm) {
                    flex-wrap: wrap;

                    & > button {
                        margin-top: 10px;
                        width: 100%;
                    }
                }

                .chat-footer-profile {
                    height: 50px;
                    width: 50px;
                    min-width: 50px;
                    margin-right: 15px;
                    border-radius: 50%;
                    border: 1px solid $border-color-light;

                    img {
                        height: 100%;
                        width: 100%;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }

                .chat-footer-form {
                    flex-grow: 1;
                    position: relative;
                    display: flex;
                    margin-right: 15px;
                    border-radius: 10px;
                    background-color: $border-color-light;
                    padding: 10px 20px;
                    height: 50px;

                    input {
                        background-color: transparent;
                        width: 100%;
                        border: 0;
                        appearance: none;
                    }

                    button {
                        font-size: 24px;
                        padding: 0;
                    }

                    @media screen and (max-width: $screen-sm) {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

.user-status {
    position: absolute;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    border: 2px solid $white-color;
    display: none;
    content: "";
    background-color: $gray-1;
    top: 0;
    right: 0;

    &.online {
        background-color: $success-color;
    }
}