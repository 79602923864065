.row {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    flex-wrap: wrap;

    .col-full {
        width: 100%;
        padding: 0 15px;
    }

    .col-half {
        width: 50%;
        padding: 0 15px;

        @media screen and (max-width: $screen-sm) {
            width: 100%;
        }
    }

    .col-3 {
        width: 33.33%;
        padding: 0 15px;

        @media screen and (max-width: $screen-lg) {
            width: 50%;
        }

        @media screen and (max-width: $screen-sm) {
            width: 100%;
        }
    }

    .sidebar {
        width: 20%;
        padding: 0 15px;

        @media screen and (max-width: $screen-xxl) {
            width: 25%;
        }

        @media screen and (max-width: $screen-lg) {
            width: 30%;
        }

        @media screen and (max-width: $screen-md) {
            width: 100%;
        }
    }

    .content {
        width: 80%;
        padding: 0 15px;

        &.full {
            width: 100%;

            .content-box {
                padding: 21px;
            }
        }

        @media screen and (max-width: $screen-xxl) {
            width: 75%;
        }

        @media screen and (max-width: $screen-lg) {
            width: 70%;
        }

        @media screen and (max-width: $screen-md) {
            width: 100%;
        }
    }
}