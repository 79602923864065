// Site Colors

$primary-color: #0069a7;
$secondary-color: #8c8787;
$accent-color: #e2f3ff;
$white-color: #fff;
$black-color: #000;
$body-bg: #fafafa;
$sidebar-hover: rgba(224,236,255,.5);
$sidebar-border: #e3e3e3;
$form-control-color: #333333;

// Text colors

$body-text-color: #434345;
$success-color: #00b050;
$warning-color: #ed7d31;
$danger-color: #ee1432;
$info-color: #e5f4fe;
$table-body-color: #f1f6fa;
$tag-bg: #5eb3e4;

// Gray

$gray-1: #ababab;
$gray-2: #ebebeb;
$gray-3: #434345;
$gray-4: #404040;

// Borders

$border-color: #d5d5d5;
$border-color-light: #f3f3f3;

// Shadow

$shadow: 0px 0px 10px #0000001a;
$shadow-lg: 0px 0px 20px #00000029;
$sidebar-shadow: -1px 6px 12px #0000001a;

$footer-bg: #434345;

// Media size
$screen-xxs: 298px;
$screen-xs: 576px;
$screen-sm: 768px;
// $screen-: 992px;
$screen-md: 1024px;
$screen-lg: 1280px;
$screen-xl: 1366px;
$screen-xxl: 1440px;
$screen-wide: 1680px;

// Fonts
$font-body: "Poppins", sans-serif;
