@import "../common/variable";
@import "../common/mixins";

.facility-list {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(7, 1fr);
    margin-bottom: 20px;
    @media screen and (max-width: $screen-xl) {
        grid-template-columns: repeat(6, 1fr);
    }
    @media screen and (max-width: $screen-lg) {
        grid-template-columns: repeat(4, 1fr);
    }
    @media screen and (max-width: $screen-md) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: $screen-sm) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: $screen-xs) {
        grid-template-columns: repeat(1, 1fr);
    }
    .facility-list-item {
        text-decoration: none;
        border-radius: 10px;
        padding: 14px 25px;
        background-color: $border-color-light;
        color: $body-text-color;
        display: block;
        text-align: center;

        @media screen and (max-width: $screen-xl) {
            flex-grow: 1;
        }

        &.active, &:hover {
            background-color: $primary-color;
            color: $white-color;
            font-weight: 500;
        }
    }
}

.facility-content {
    padding: 40px;
    border: 1px solid $border-color;
    border-radius: 10px;
    .facility-logo {
        margin-bottom: 30px;
    }
    .facility-details-content {
        h6 {
            color: $primary-color;
            margin-bottom: 20px;
        }
        a {
            color: $tag-bg;
        }
        table {
            border: 1px solid transparent;
            text-align: left;
            margin-bottom: 20px;
            thead {
                tr {
                    th {
                        background-color: $primary-color;
                        color: $white-color;
                        border-color: lighten($primary-color, 4);
                    }
                }
            }

            tbody {
                tr {
                    td {
                        background-color: $body-bg;
                        border-color: $white-color;
                    }
                }
            }
        }
        .contact-bar {
            background-color: $tag-bg;
            color: $white-color;
            padding: 21px 30px;
            border-radius: 5px;
            a {
                text-decoration: none;
                color: $white-color;
                margin: 0 30px 0 10px;
            }
        }
    }
}