@import "./variable";
@import "./mixins";

// Typography
.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.decoration-none {
    text-decoration: none;
}

.cursor-pointer {
    cursor: pointer;
}

.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

//////// Text colors
.text-white {
    color: $white-color;
}

.text-black {
    color: $black-color;
}

.text-danger {
    color: $danger-color;
}

.text-success {
    color: $success-color;
}

.text-warning {
    color: $warning-color;
}

.text-primary {
    color: $primary-color;
}

.text-body {
    color: $body-text-color;
}

//////// Background Color
.bg-danger {
    background-color: $danger-color;
}

.bg-success {
    background-color: $success-color;
}

.bg-warning {
    background-color: $warning-color;
}

.bg-primary {
    background-color: $primary-color;
}

.bg-secondary {
    background-color: $secondary-color;
}

.bg-body {
    background-color: $body-text-color;
}

// Display
.d-flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-column {
    flex-flow: column;
}

.align-start {
    align-items: flex-start;
}

.align-end {
    align-items: flex-end;
}

.align-center {
    align-items: center;
}

.justify-start {
    justify-content: flex-start;
}

.justify-end {
    justify-content: flex-end;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.d-block {
    display: block;
}

.d-inline-block {
    display: inline-block;
}

.d-grid {
    display: grid;
}

// Utility
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.ml-auto {
    margin-left: auto;
}

.mr-auto {
    margin-right: auto;
}

.m-0 {
    margin: 0;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-sm-1 {
    @media screen and (max-width: $screen-sm) {
        margin-bottom: 10px;
    }
}

.mb-sm-2 {
    @media screen and (max-width: $screen-sm) {
        margin-bottom: 20px;
    }
}

.mb-sm-3 {
    @media screen and (max-width: $screen-sm) {
        margin-bottom: 30px;
    }
}

.mb-lg-1 {
    @media screen and (max-width: $screen-lg) {
        margin-bottom: 10px;
    }
}

.mb-lg-2 {
    @media screen and (max-width: $screen-lg) {
        margin-bottom: 20px;
    }
}

.mb-lg-3 {
    @media screen and (max-width: $screen-lg) {
        margin-bottom: 30px;
    }
}

.mt-0 {
    margin-top: 0;
}

.ml-0 {
    margin-left: 0;
}

.mr-0 {
    margin-right: 0;
}

.mt-3 {
    margin-top: 30px;
}

.mt-2 {
    margin-top: 20px;
}

.mt-1 {
    margin-top: 10px;
}

.ml-3 {
    margin-left: 30px;
}

.ml-2 {
    margin-left: 20px;
}

.ml-1 {
    margin-left: 10px;
}

.mr-3 {
    margin-right: 30px;
}

.mr-2 {
    margin-right: 20px;
}

.mr-1 {
    margin-right: 10px;
}

.mb-3 {
    margin-bottom: 30px;
}

.mb-2 {
    margin-bottom: 20px;
}

.mb-1 {
    margin-bottom: 10px;
}

.w-100 {
    width: 100%;
}

.w-75 {
    width: 75%;

    @media screen and (max-width: $screen-md) {
        width: 100%;
    }
}

.w-50 {
    width: 50%;

    @media screen and (max-width: $screen-sm) {
        width: 100%;
    }
}

.w-25 {
    width: 25%;

    @media screen and (max-width: $screen-md) {
        width: 50%;
    }

    @media screen and (max-width: $screen-sm) {
        width: 100%;
    }
}

.gap-1 {
    gap: 10px;
}

.gap-2 {
    gap: 20px;
}

.gap-3 {
    gap: 30px;
}

// Custome toaster width 
.Toastify__toast-container.Toastify__toast-container--top-center {
    width: auto;
}

// Added Overlay for toater
.Toastify.overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.1);
    height: 100%;
    width: 100%;
    transition-duration: 0.3s;
}

.btn-chat, .chat-popup-container {
    display: none;
}

.is-active-true,
.is-active-false {
    display: inline-block;
    min-width: 86px;
    padding: 4px;
    border-radius: 6px;
    text-align: center;
}

.is-active-true {
    color: #166534;
    background-color: #d3fcdf;
}

.is-active-false {
    background-color: #ffd4d4;
    color: #B91C1C;
}

.cursor-move {
    cursor: move;
}