@import "../common/variable";
@import "../common/mixins";

.auth-page-wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding: 30px 150px 30px 60px;
    background-size: cover;
    position: relative;

    .bg-img {
        position: absolute;
        inset: 0;
        object-fit: cover;
        height: 100%;
        width: 100%;
    }

    .logo {
        position: absolute;
        top: 60px;
        left: 60px;
        width: 171px;
        height: 40px;
        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }

    @media screen and (max-width: $screen-lg) {
        padding: 30px 100px 30px 40px;
    }

    @media screen and (max-width: $screen-md) {
        padding: 30px 50px 30px 30px;
    }

    @media screen and (max-width: $screen-sm) {
        padding: 30px;
        flex-flow: column;

        .logo {
            width: 100px;
            height: 30px;
            position: relative;
            top: 0;
            left: 0;
            margin-bottom: 30px;
        }
    }
    .auth-card {
        z-index: 1;
    }
}

.verification-digits {
    justify-content: center;
    margin-bottom: 30px;

    .otp-digit {
        margin: 0 10px;
        width: 50px !important;
        height: 50px;
        border-radius: 5px;
        border: 1px solid $border-color;
        background-color: $white-color;
        font-weight: 500;

        &.error {
            border-color: $danger-color;
            color: $danger-color;
        }

        &:focus {
            box-shadow: $shadow;
            color: $primary-color;
        }
    }
}