$font-body: "Poppins", sans-serif;

@mixin font-family($face, $weight) {
    font-family: $face;
    font-weight: $weight;
}

@mixin body-font {
    @include font-family($font-body, normal);
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
}

//////// Font-size as h1-h6 headings ////////
@mixin font-xl {
    font-size: 50px;
    line-height: 60px;
}

@mixin font-lg {
    font-size: 60px;
    line-height: 80px;
    letter-spacing: -0.5px;
}

@mixin font-md {
    font-size: 36px;
    line-height: 54px;
}

@mixin font-sm {
    font-size: 30px;
    line-height: 42px;
}

@mixin font-xs {
    font-size: 24px;
    line-height: 36px;
}

@mixin font-xxs {
    font-size: 20px;
    line-height: 30px;
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -o-border-radius: $radius;
    border-radius: $radius;
}

@mixin opacity($opacity) {
    -webkit-opacity: $opacity;
    -moz-opacity: $opacity;
    -o-opacity: $opacity;
    -ms-opacity: $opacity;
    opacity: $opacity;
}

@mixin transition($seconds, $timing: ease) {
    -webkit-transition: all $seconds ease;
    -moz-transition: all $seconds ease;
    -ms-transition: all $seconds ease;
    -o-transition: all $seconds ease;
    transition: all $seconds $timing;
}

@mixin transform($property) {
    -o-transform: $property;
    -webkit-transform: $property;
    -ms-transform: $property;
    -moz-transform: $property;
    transform: $property;
}

@mixin flexbox() {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin flexboxImp() {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
}

@mixin text-shadow($x-axis, $y-axis, $blur, $color) {
    -ms-text-shadow: $x-axis $y-axis $blur $color;
    -o-text-shadow: $x-axis $y-axis $blur $color;
    -webkit-text-shadow: $x-axis $y-axis $blur $color;
    -moz-text-shadow: $x-axis $y-axis $blur $color;
    text-shadow: $x-axis $y-axis $blur $color;
}

@mixin box-shadow($x-axis, $y-axis, $blur, $color) {
    -ms-box-shadow: $x-axis $y-axis $blur $color;
    -o-box-shadow: $x-axis $y-axis $blur $color;
    -webkit-box-shadow: $x-axis $y-axis $blur $color;
    -moz-box-shadow: $x-axis $y-axis $blur $color;
    box-shadow: $x-axis $y-axis $blur $color;
}

@mixin box-shadow-none {
    -ms-box-shadow: none;
    -o-box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

@mixin text-shadow-none {
    -ms-text-shadow: none;
    -o-text-shadow: none;
    -webkit-text-shadow: none;
    -moz-text-shadow: none;
    text-shadow: none;
}

@mixin box($property) {
    box-sizing: $property;
    -moz-box-sizing: $property;
    -webkit-box-sizing: $property;
    -o-box-sizing: $property;
}

@mixin trans-anim-none {
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
}

@mixin space-none {
    margin: 0;
    padding: 0;
}

@mixin app-none {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -ms-appearance: none;
    appearance: none;
}

@mixin point-none {
    -webkit-pointer-events: none;
    -moz-pointer-events: none;
    -o-pointer-events: none;
    -ms-pointer-events: none;
    pointer-events: none;
}