@import "../common/variable";
@import "../common/mixins";
@import "../components/card";

.error-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    text-align: center;
    flex-grow: 1;
    padding: 30px 50px;
    .error-card {
        padding: 2rem 4rem;
    }
}