@import "../common/variable";
@import "../common/mixins";

.sidebar {
    position: fixed;
    top: 110px;
    width: 100%;
    z-index: 2;
    background-color: $white-color;
    @media screen and (max-width: $screen-md) {
        position: initial;
        top: auto;
    }
    .sidebar-menu {
        margin: 0;
        padding: 0;

        .menu-item {
            margin-bottom: 0;
            padding-left: 0;
            &::before {
                display: none;
            }

            a {
                color: $gray-4;
                text-decoration: none;
                background-color: $white-color;
                border-bottom: 1px solid $sidebar-border;
                display: flex;
                align-items: center;
                gap:8px;
                font-size: 18px;
                font-weight: 500;
                font-family: $font-body;
                padding: 14px 16px;

                i {
                    vertical-align: middle;
                }

                .right-arrow {
                    margin-left: auto;
                    font-size: 12px;
                    color: #bdbdbd;
                    font-weight: 100;
                }

                &:hover {
                    background-color: $sidebar-hover;
                }

                &.active {
                    background-color: $primary-color;
                    color: $white-color;
                    border-color: $primary-color;

                    .right-arrow {
                        color: $white-color;
                    }
                }
            }
        }
    }
}

.site-sidebar {
    max-width: 330px;
    padding: 0px 0 20px;
    box-shadow: $sidebar-shadow;
    height: 100%;
}