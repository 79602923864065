@import "../common/variable";
@import "../common/mixins";
@import "../components/confirm-popup";
@import "../components/button";

.popup{
  position: fixed;
  top:0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.7);
  z-index: 11;

   .overlay{
    display: block;
  }
  .content{
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 780px;
    z-index: 5;
    text-align: center;
    padding: 50px;
    box-sizing: border-box;
    max-height: calc(100vh - 50px);
    overflow: auto;
    display: flex;
    flex-flow: column;

    @media screen and (max-width: '992px') {
       max-width: 600px; 
       width: 100%;
    }
     @media screen and (max-width: $screen-sm) {
       max-width: 500px; 
       width: 100%;
    }
    @media screen and (max-width: $screen-xs) {
        max-width: calc(100% - 40px); 
        width: 100%;
    }
    
    .close-btn{
      position: absolute;
      right: 20px;
      top: 20px;
      width: 30px;
      height: 30px;
      background:#0069a7;
      color: #fff;
      font-size: 25px;
      font-weight: 600;
      line-height: 30px;
      text-align: center;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .heading{
    text-align: left;
    font-size: 24px;
    color: #0069a7;
    margin-bottom: 10px;
  }
  .main-content{
    border-top: 1px solid #E2E0E0;
    padding: 30px 0px;
    border-bottom: 1px solid #E2E0E0;
    overflow: auto;

    .main-content-top{
      width: auto;
      padding: 44px 94px 44px 94px;
      border: 1px dashed #E2E0E0;

      @media screen and (max-width: $screen-sm) {
        padding: 30px 21px 30px 21px;
      }

      i{
        font-size: 32px;
      }
      .file-heading{
        padding-top: 10px;
        font-size: 16px;
        color: #0069a7;
      }
      .file-text{
        padding-top: 10px;
        padding-bottom: 20px;
        margin-bottom: 0;
        color: #787A7C;
        font-size: 16px;
      }
      
      .browsefile-div{
        position: relative;
        display: inline-block;
        
        input{
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          opacity: 0;
          cursor: pointer;
        }
        .selected-file-name{
          padding-top: 10px;
          font-size: 16px;
          word-break: break-word;
        }
      }
      .browse-file-btn{
        width: 174px;
        height: 55px;
        border: 1px solid #E2E0E0;
        border-radius: 10px;
        font-weight: 600;
        font-size: 16px;
        color: #0069a7;
      }
    }
    .main-content-bottom{
      width: 100%;
      padding-top: 35px;
      p{
        text-align: left;
        font-size: 16px;
        margin-bottom: 20px;
      }
      .download-file{
        text-align: left;
        button{
          font-weight: 600;
          font-size: 16px;
          padding: 16px 30px;
          border: 1px solid #E2E0E0;
          border-radius: 10px;
          color: #0069a7;
        }
        .download-icon{
          padding-right: 10px;
          vertical-align: middle;
         
        }
      }
    }
  }

  .footer-content{
    text-align: right;
    padding-top: 30px;
  }
}