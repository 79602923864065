.popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.7);
	z-index: 11;

	.overlay {
		display: block;
	}

	.content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: #fff;
		width: 780px;
		z-index: 5;
		padding: 40px;
		box-sizing: border-box;
		max-height: calc(100vh - 50px);
		overflow: auto;
		display: flex;
		flex-flow: column;

		@media screen and (max-width: "992px") {
			max-width: 600px;
			width: 100%;
		}
		@media screen and (max-width: $screen-sm) {
			max-width: 500px;
			width: 100%;
		}
		@media screen and (max-width: $screen-xs) {
			max-width: calc(100% - 40px);
			width: 100%;
		}

		.close-btn {
			position: absolute;
			right: 20px;
			top: 20px;
			width: 30px;
			height: 30px;
			background: #0069a7;
			color: #fff;
			font-size: 25px;
			font-weight: 600;
			line-height: 30px;
			text-align: center;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.popup-header {
		text-align: left;
		font-size: 24px;
		color: #0069a7;
		margin-bottom: 10px;
	}
	.main-content {
		min-height: 340px;
		border-top: 1px solid #e2e0e0;
		padding: 30px 2px;
		border-bottom: 1px solid #e2e0e0;
		text-align: left;
		scrollbar-width: thin;

		&::-webkit-scrollbar {
			width: 0.5rem;
		}

		textarea {
			resize: none;
			height: 100px;
			font-size: 16px;

			&:focus,
			&:focus-visible {
				outline: 1px solid$primary-color;
				border-color: $primary-color;
			}
		}

		.main-content-file-upload {
			width: 100%;
			padding: 44px 94px 44px 94px;
			border: 1px dashed #e2e0e0;

			@media screen and (max-width: $screen-sm) {
				padding: 30px 21px 30px 21px;
			}

			i {
				font-size: 26px;
				margin-right: 4px;
			}

			.file-heading {
				display: inline-block;
				padding-top: 10px;
				padding-bottom: 10px;
				font-size: 16px;
				color: #0069a7;
			}

			.file-name-text {
				margin-bottom: 0;
				color: #787a7c;
				font-size: 16px;
			}
		}
	}

	.popup-footer {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-top: 24px;
		gap: 20px;
	}
}
