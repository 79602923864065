@import "../common/variable";

.privacy-policy-page {

    ul,
    ol,
    li {
        all: unset;
    }

    ol {
        list-style-type: decimal;

        ul {
            list-style-type: circle;
            margin-block-start: 0px;
            margin-block-end: 0px;

            ul {
                list-style-type: square;
            }
        }
    }

    ul {
        list-style-type: disc;

        ul {
            margin-block-start: 0px;
            margin-block-end: 0px;
        }
    }

    li {
        display: list-item;
        text-align: -webkit-match-parent;
        unicode-bidi: isolate;
        margin-left: 30px;
        margin-top: 5px;
        @media screen and (max-width: $screen-sm) {
            margin-top: 3px;
            margin-left: 20px;
        }

        &::before {
            display: none;
        }
    }
}

h5,h6 {
    &:not(:first-child) {
        margin-top: 30px;
        @media screen and (max-width: $screen-sm) {
            margin-top: 15px;
        }
    }
}
h3 {
    @media screen and (max-width: $screen-sm) {
        font-size: 30px;
    }
}
h5 {
    @media screen and (max-width: $screen-sm) {
        font-size: 20px;
    }
}
.p-header {
    padding: 31.5px 0;
    @media screen and (max-width: $screen-sm) {
        padding: 15px 0;
    }
}
p {
    @media screen and (max-width: $screen-sm) {
        margin-bottom: 15px;
    }
}
.page-wrapper {
    .page
    {
        .page-header 
        {
            .page-header-left
            {
                @media screen and (max-width: $screen-lg) {
                    margin-bottom: 0;
                }
            }
        }

    }

} 