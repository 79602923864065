@import "../common/variable";
@import "../common/mixins";
@import "../components/confirm-popup";
@import "../components/button";
@import "../components/popup";

.flex {
	display: flex;
	align-items: center;
	gap: 10px;
}

.justify-content-between {
	justify-content: space-between;
}

.change-alert-status {
	display: flex;
	align-items: center;

	span {
		color: $black-color;
	}

	button {
		height: 28px;
		width: 80px;
		border: 1px solid black;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.active {
		background-color: $success-color;
		border: 1px solid $success-color;
		color: white;

		&.right {
			background-color: $danger-color;
			border: 1px solid $danger-color;
		}
	}

	.in-active {
		background-color: white;
		border: 1px solid $border-color;
		color: $secondary-color;
	}

	.left {
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}

	.right {
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
	}
}

.user-type {
	display: flex;
	justify-content: end;
	align-items: center;
	width: 300px;
	position: relative;

	span {
		width: 35%;
	}
}

.status {
	display: flex;
	justify-content: end;
	align-items: center;
	width: 300px;
	position: relative;

	span {
		width: 35%;
	}
}

.homescreen-table {
	tr {
		th {
			padding: 20px 16px;
		}
		th:first-child,
		td:first-child {
			text-align: left;
			padding-left: 20px;
			width: 16%;
			@media screen and (max-width: $screen-lg) {
				padding-left: 15px;
			}

			@media screen and (max-width: $screen-sm) {
				padding-left: 8px;
			}
		}

		th:nth-child(2),
		td:nth-child(2) {
			text-align: center;
			word-break: break-word;
			width: 6%;
		}

		th:nth-child(3),
		td:nth-child(3) {
			text-align: center;
			word-break: break-word;
			width: 12%;
		}

		th:nth-child(4),
		td:nth-child(4) {
			text-align: center;
			word-break: break-word;
			width: 8%;
		}

		th:nth-child(5),
		td:nth-child(5) {
			text-align: center;
			word-break: break-word;
			width: 10%;
		}

		th:nth-child(6),
		td:nth-child(6) {
			text-align: center;
			width: 9%;
		}

		th:nth-child(7),
		td:nth-child(7) {
			text-align: center;
			width: 8%;
		}

		th:last-child,
		td:last-child {
			width: 6%;
			text-align: center;
			@media screen and (min-width: $screen-xl) {
				padding-left: 20px;
				padding-right: 20px;
			}
		}
	}
}