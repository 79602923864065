/*** Default Style Reset ***/
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 16px;
  height: 100%;
}

body {
  height: 100%;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  background-color: $body-bg;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

img,
a,
input,
nav,
textarea,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

figure {
  margin: 0;
  display: inherit;
}

button {
  border: none;
  background: none;
  cursor: pointer;
  outline: none !important;
  @include transition(0.3s);
}

input {
  @include body-font;
}

input:focus,
select:focus {
  outline: 0;
}

::-webkit-input-placeholder {
  color: $gray-1;
}

:-ms-input-placeholder {
  color: $gray-1;
}

::placeholder {
  color: $gray-1;
}

ul,
ol,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
  outline: none;
  color: $primary-color;
  text-decoration: underline;
  @include transition(0.3s);

  &:hover {
    color: darken($primary-color, 3);
  }
}

img {
  display: inline-block;
  max-width: 100%;
  width: auto;
}

blockquote,
figure,
address {
  font-style: normal;
}

table {
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid $border-color;
    padding: 10px;
  }
}

.templates-wrapper {
  overflow: hidden;
  width: 100%;
}

main {
  padding: 0px;
  width: 100%;
}

.container {
  width: 100%;
  padding: 0 50px;
  margin: 0 auto;

  @media screen and (max-width: $screen-md) {
    padding: 0 30px;
  }

  @media screen and (max-width: $screen-sm) {
    padding: 0 15px;
  }
}

section,
.section {
  width: 100%;
}

p {
  margin-bottom: 30px;
}

//////// Heading ////////
h1,
.h1 {
  @include font-xl;
  margin-bottom: 30px;
}

h2,
.h2 {
  @include font-lg;
  margin-bottom: 30px;
}

h3,
.h3 {
  @include font-md;
  margin-bottom: 30px;
}

h4,
.h4 {
  @include font-sm;
  margin-bottom: 30px;
}

h5,
.h5,
.subTitle1 {
  @include font-xs;
}

h6,
.h6,
.subTitle2 {
  @include font-xxs;
}

hr {
  margin: 2.5rem 0;
  opacity: 0.3;
}

//////// Listing ////////
ul {
  margin: 0px 0px 30px;
  padding: 0;

  li {
    list-style: none;
    margin: 0px 0px 5px;
    position: relative;

    &:before {
      // content: "";
      width: 4px;
      height: 4px;
      position: absolute;
      left: 0px;
      top: 10px;
      border-radius: 100%;
      background-color: $body-text-color;
    }
  }
}

ul.list-unstyled {
  li {
    margin: 0px;
    padding: 0px;

    &:before {
      content: normal;
    }
  }
}

ol {
  margin: 0px 0px 30px;
  padding: 0px;
  list-style: none;

  li {
    counter-increment: av-counter;
    margin: 0px 0px 20px;
    padding: 0px 0px 0px 20px;
    position: relative;

    &:before {
      content: counter(av-counter) ".";
      counter-increment: none;
      position: absolute;
      left: 0px;
      top: 0px;
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.pagination li {
  display: inline-block;
  margin: 0 0.2rem;
  border: 1px solid #dee2e6;
  cursor: pointer;
}

.pagination li.active {
  background-color: $primary-color;
  color: #fff;
  border-color: $primary-color;
}

.pagination li a {
  padding: 0.3rem 0.5rem;
  display: inline-block;
  text-align: center;
  text-decoration: none;
}

.pagination li.active a {
  color: #fff;
}

// .pagination li:hover {
//     background-color: $primary-color;
//     color: #fff !important;
//     border-color: $primary-color;
//     a {
//         color: #fff;
//     }
// }