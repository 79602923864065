.page-wrapper {
    display: flex;
    flex-flow: column;
    max-width: 100%;
    min-height: 100vh;

    .page {
        flex-grow: 1;
        padding: 30px 30px 30px 360px;
        position: relative;
        z-index: 1;

        &.infinite {
            overflow: auto;
            max-height: calc(100vh - 208px);
            // .page-loader {
            //     top: unset;
            //     bottom: 110px;
            //     left: 50%;
            //     transform: translateX(-50%);
            //     height: auto;
            //     width: auto;
            //     padding: 10px;
            //     border-radius: 50%;
            //     span {
            //         border-width: 3px;
            //         height: 40px;
            //         width: 40px;
            //     }
            // }
        }

        @media screen and (max-width: $screen-md) {
            padding: 30px 30px;
        }

        @media screen and (max-width: $screen-sm) {
            padding: 15px;
        }

        .page-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;
            @media screen and (max-width: $screen-sm) {
                margin-bottom: 15px;
                flex-direction: column;
            }

            & > h3{
                padding-bottom: 2px;
                @media screen and (max-width: $screen-sm) {
                   padding-bottom: 13px;
                }
            }
            
           & > .change-status{
            // justify-content: flex-start;
            @media screen and (max-width: $screen-sm) {
                justify-content: flex-start;
                padding-left: 45px;
             }
            }

            .page-header-left {
                & > h3 {
                    line-height: 1;

                    & > i {
                        line-height: 24px;
                    }
                }
            }

            .page-header-right {
                min-width: 22%;
                text-align: right;

                & > .form-group {
                    margin-bottom: 0;

                    .form-control {
                        padding: 14px 65px 13px 15px;

                        @media screen and (max-width: $screen-sm) {
                            padding: 12px 50px 11px 15px;
                        }
                    }

                    .input-icon {
                        left: unset;
                        right: 0;
                        border-radius: 0 5px 5px 0;
                    }
                }
            }

            @media screen and (max-width: $screen-sm) {
                flex-direction: column;
                align-items: stretch;

                .page-header-left {
                    margin-bottom: 15px;
                }
            }
        }
    }
}