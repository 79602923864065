@import "../common/variable";
@import "../common/mixins";
@import "../components/confirm-popup";
@import "../components/button";

.form-filter-group {
  .upload-sheet-btn{
    button{
      padding: 10px;
      border-radius: 5px;
      background-color: #0069a7;
      color: white;
      font-weight: 600;
      font-size: 16px;
      @media screen and (max-width: '992px') {   // add css for this part
        margin-bottom: 10px;
      }
      &:hover {
        background-color: #005387; 
      }
    }
  }

  .upload-file{
    button{
      padding: 10px;
      border-radius: 5px;
      background-color: #0069a7;
      color: white;
      margin-right: 20px;
      font-weight: 600;
      font-size: 16px;
      @media screen and (max-width: $screen-lg) {
        margin-bottom: 10px;
      }
      &:hover {
        background-color: #005387; 
      }
    }
  }

}

.ignored-records-table,.imported-records-table {
  tr {
    th:first-child,
    td:first-child {
      text-align: left;
      padding-left: 35px;
      width: 15%;
      @media screen and (max-width: $screen-lg) {
        padding-left: 15px;
      }

      @media screen and (max-width: $screen-sm) {
        padding-left: 8px;
      }
    }

    th:last-child,
    td:last-child {
      width: 40%;
      text-align: center;
      @media screen and (min-width: $screen-xl) {
        padding-left: 50px;
        padding-right: 50px;
      }
    }

    th:nth-child(2),
    td:nth-child(2) {
      text-align: center;
      word-break: break-word;
      width: 15%;
    }

    th:nth-child(3),
    td:nth-child(3) {
      text-align: center;
      word-break: break-word;
      width: 10%;
    }

    th:nth-child(4),
    td:nth-child(4) {
      text-align: center;
      word-break: break-word;
      width: 10%;
    }

    th:nth-child(5),
    td:nth-child(5) {
      text-align: center;
      word-break: break-word;
      width: 10%;
    }
  }
  .no-data-found{
    color:#0069a7;
  }
}
@media screen and (max-width: $screen-lg) {
  .page .page-header-left {
    width: 100%;
    margin-bottom: 20px;
  }
  .form-filter-group {
    flex-wrap: wrap;
    width: 100%;
  }
  .form-filter-group .search {
    width: 100%;
  }
  .form-filter-group .status {
    width: 100%;
    padding-top: 10px;
  }
  .form-filter-group .dropdown {
    padding-left: 0;
    width: 100%;
    margin-left: 0;
  }
}


