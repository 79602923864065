@import "../common/variable";
@import "../common/mixins";
@import "../layout/grid";

.user-unapproved  {
    display: flex;
    flex-grow: 1;
    padding: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .user-unapproved-img {
        max-height: 300px;
    }
}