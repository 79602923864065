@import "./variable";
@import "./mixins";

@font-face {
    font-family: 'icomoon';
    src: url('../../fonts/icomoon.eot?aqg6id');
    src: url('../../fonts/icomoon.eot?aqg6id#iefix') format('embedded-opentype'),
        url('../../fonts/icomoon.ttf?aqg6id') format('truetype'),
        url('../../fonts/icomoon.woff?aqg6id') format('woff'),
        url('../../fonts/icomoon.svg?aqg6id#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon', sans-serif !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: inherit;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-chevron-right-solid:before {
    content: "\e922";
}

.icon-Total-Employees:before {
  content: "\e921";
}
.icon-union:before {
  content: "\e91f";
}
.icon-registered:before {
  content: "\e920";
}
.icon-delete:before {
  content: "\e91e";
}
.icon-upload-card:before {
  content: "\e91d";
}
.icon-cloud-upload:before {
  content: "\e91c";
}
.icon-download:before {
  content: "\e91b";
}
.icon-chat-alt:before {
    content: "\e919";
}

.icon-close:before {
    content: "\e91a";
}

.icon-send:before {
    content: "\e917";
}

.icon-menu:before {
    content: "\e918";
}

.icon-email:before {
    content: "\e916";
}

.icon-chat:before {
    content: "\e914";
}

.icon-additional:before {
    content: "\e915";
}

.icon-camera:before {
    content: "\e913";
}

.icon-crosshair:before {
    content: "\e900";
}

.icon-back:before {
    content: "\e901";
}

.icon-edit:before {
    content: "\e902";
}

.icon-eye:before {
    content: "\e904";
}

.icon-family:before {
    content: "\e905";
}

.icon-id-card:before {
    content: "\e906";
}

.icon-location:before {
    content: "\e907";
}

.icon-lock:before {
    content: "\e908";
}

.icon-planning:before {
    content: "\e909";
}

.icon-call:before {
    content: "\e90b";
}

.icon-facebook:before {
    content: "\e911";
}

.icon-linkedin:before {
    content: "\e912";
}

.icon-youtube:before {
    content: "\e90c";
}

.icon-user:before {
    content: "\e90d";
}

.icon-info:before {
    content: "\e90e";
}

.icon-target:before {
    content: "\e90f";
}

.icon-eye-cancel:before {
    content: "\e910";
}

.icon-exit:before {
    content: "\e90a";
}

.icon-search:before {
    content: "\e903";
}

body {
    @include body-font;
    color: $body-text-color;
}