@import "../common/variable";
@import "../common/mixins";

.no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: $gray-1;
    height: 40vh;

    i {
        margin-bottom: 15px;
        font-size: 3rem;
        line-height: 1;
        color: lighten($primary-color, 50);
    }
}