@import "../common/variable";
@import "../common/mixins";

.page-loader {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($body-bg, 0.5);
    display: grid;
    place-content: center;
    opacity: 0;
    visibility: hidden;
    @include transition(1s);
    z-index: 2;

    &.show {
        opacity: 1;
        visibility: visible;
        @include transition(1s);
    }

    span {
        width: 75px;
        height: 75px;
        margin: 0;
        background: transparent;
        border-top: 4px solid $primary-color;
        border-right: 4px solid transparent;
        border-radius: 50%;
        -webkit-animation: 1s spin linear infinite;
        animation: 1s spin linear infinite;
    }
}

@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}