@import "../common/variable";
@import "../common/mixins";


.custom-title-class {
  font-size: 22px; /* Adjust the font-size as needed */
  margin-bottom: 0;
}

.member-planname-relation {
  @media screen and (max-width: $screen-wide) {
    padding-left: 40px;
  }
  @media screen and (max-width: $screen-sm) {
    padding-left: 0;
  }
}
.member-card-list {
  .main-member-card-items {
    padding-right: 90px;

    @media screen and (max-width: $screen-sm) {
      padding-right: 0;
    }
  }
}
.member-email-id {
  word-break: break-word;
}
.member-card-list {
  padding-left: 35px;
  margin-bottom: 0;
  display: flex;
  @media screen and (max-width: $screen-wide) {
    flex-wrap: wrap;
  }
  @media screen and (max-width: $screen-sm) {
    padding-left: 0;
  }
}
.member-card-items {
  display: inline-block;
  padding-right: 30px;

  @media screen and (max-width: $screen-wide) {
    width: 50%;
  }
  @media screen and (max-width: $screen-sm) {
    width: 100%;
    text-align: center;
    padding-right: 0;
  }
}

.member-card-items:last-child {
  padding-right: 0;

  @media screen and (max-width: $screen-sm) {
    padding-right: 0;
    padding-left: 0;
  }
}

.page-header > h3 {
  @media screen and (max-width: $screen-sm) {
    font-size: 25px;
    padding-bottom: 0;
  }
  @media screen and (max-width: $screen-xxs) {
    font-size: 18px;
    padding-bottom: 0;
    margin-bottom: -15px;
  }
}

.member-detail-row {
  display: flex;

  div {
    margin-left: 37px;
  }
}

.member-card-detail {
  justify-content: center;
  align-items: center;
}

.member-card-detail {
  .member-name {
    margin-bottom: 10px;
    margin-left: 37px;

    @media screen and (max-width: $screen-lg) {
      padding-bottom: 15px;
      padding-top: 15px;
    }

    @media screen and (max-width: $screen-md) {
      padding-bottom: 15px;
      padding-top: 15px;
    }

    @media screen and (max-width: $screen-sm) {
      margin-left: 0px;
      text-align: center;
    }
  }
}

.member-detail .top {
  margin-top: 20px;
}

.member-detail .row {
  display: flex;
}

.bold {
  font-weight: 500;
}

.change-status {
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    margin-left: 10px;
  }

  span {
    color: $black-color;
  }

  button {
    height: 25px;
    width: 70px;
    border: 1px solid black;
  }

  .active {
    background-color: $success-color;
    border: 1px solid $success-color;
    color: white;

    &.right {
      background-color: $danger-color;
      border: 1px solid $danger-color;
    }
  }

  .in-active {
    background-color: white;
    border: 1px solid $border-color;
    color: $border-color;
  }

  .left {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .right {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.section-row {
  padding-top: 23px;

  .left {
    width: 100%;
    position: relative;

    button.delete-spouce{
      position: absolute;
      top: 10px;
      right: 10px;   
      background-color: #d33;
      border-radius: 5px;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      padding: 6px 12px;
      margin-bottom: 10px;

      @media screen and (max-width: $screen-lg){
          right: 10px; 
      }
    }

    @media screen and (max-width: $screen-md) {
      width: 100%;
    }

    @media screen and (max-width: $screen-lg) {
      width: 100%;
      padding-right: 0px;
    }
  }
  .right {
    width: 100%;
    position: relative;
     button.delete-spouce{
      position: absolute;
      top: 10px;
      right: 10px;   
      background-color: #d33;
      border-radius: 5px;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      padding: 6px 12px;
      margin-bottom: 10px;
    }


    @media screen and (max-width: $screen-md) {
      width: 100%;
    }
    @media screen and (max-width: $screen-lg) {
      width: 100%;
      padding-left: 0px;
    }
  }
  .member-card {
    margin-bottom: 21px;
  }
}

.member-card {
  .member-card-detail {
    flex-grow: 1;
  }
}

.member-detail-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid-item {
  display: flex;
}

.inline-text {
  word-wrap: break-word;
  word-break: break-all;
}
.text-capitalize {
  text-transform: capitalize;
}
.member-main-section {
  display: flex;
  padding-left: 36px;
  padding-top: 5px;

  .member-section-details {
    padding-right: 60px;
    padding-left: 5px;
    flex: 0 0 18%;
    width: 100%;
    @media screen and (max-width: $screen-lg) {
      flex: 0 0 20%;
    }
    @media screen and (max-width: $screen-xs) {
      padding-right: 0;
    }
  }

  @media screen and (max-width: $screen-lg) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: $screen-sm) {
    padding-left: 0px;
  }

  .member-relation {
    text-align: left;

    @media screen and (max-width: $screen-sm) {
      text-align: center;
    }
    @media screen and (max-width: $screen-xs) {
      text-align: center;
    }
  }

  & > section {
    padding-right: 80px;

    @media screen and (max-width: $screen-lg) {
      width: 50%;
      padding-bottom: 10px;
      flex-wrap: wrap;
    }

    @media screen and (max-width: $screen-sm) {
      width: 100%;
      padding-bottom: 10px;
      flex-wrap: wrap;
      text-align: center;
      padding-right: 0px;
    }
  }
}

.member-card {
  @media screen and (max-width: $screen-sm) {
    flex-direction: column;
  }

  .member-icon-size {
    width: 105px;
    height: 105px;
    border-radius: 50%;

    @media screen and (max-width: $screen-md) {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      font-size: 30px;
    }

    @media screen and (max-width: "932px") {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      font-size: 25px;
    }
  }
}

@media screen and (max-width: $screen-lg) {
  .section-row {
    display: block;
    padding-top: 23px;
    justify-content: space-between;

    .member-card {
      margin-bottom: 10px;
    }
  }

  .member-detail-row {
    display: block;
  }
}


.btn-common{
  min-width: 179px;
  height: 36px;
  padding: 8px 19px;
  font-size: 16px;
  cursor: pointer;
  font-weight: 500;
  border-radius: 5px;
  display: block;
  @media screen and (max-width: $screen-lg) {
    display: inline-block;
  }
   @media screen and (max-width: $screen-xs) {
    display: block;
  }
}
.upload-card-btn{
  width: 100%;
  color: $primary-color;
  border: 1px solid $primary-color;
  @media screen and (max-width: $screen-lg) {
    width: auto;
    margin-right: 20px;
  }
  @media screen and (max-width: $screen-xs) {
    margin: 0 auto;
  }
  &:hover{
    background-color: $primary-color;
    color: $white-color;
  }
  .icon-upload-card{
    margin-right: 10px;
  }
}
.delete-btn{
  margin-top: 10px;
  color: $danger-color;
  border: 1px solid $danger-color;
    &:hover{
    background-color: $danger-color;
    color: $white-color;
  }
  .icon-delete{
    margin-right: 10px;
  }
  @media screen and (max-width: $screen-xs) {
    margin: 0 auto;
    margin-top: 10px;
  }
}
.main-section{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media screen and (max-width: $screen-lg) {
    flex-direction: column;
  }
}
.member-section-btn{
  flex-basis: content;
  @media screen and (max-width: $screen-lg) {
    padding-left: 36px;
  }
  @media screen and (max-width: $screen-sm) {
    text-align: center;
    padding-left: 0;
  }
}

.child-main-detail{
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $screen-lg) {
    flex-direction: column;
  }
}

.parent-card-detail{

  @media screen and (max-width: $screen-wide) {

    .member-card-items{
      padding-right: 0;
    }
    .member-planname-relation{
      padding-left: 40px;
      padding-right: 30px;
    }
  }
  }


// pop up for upload card
