@import "../common/variable";
@import "../common/mixins";
@import "../components/confirm-popup";
@import "../components/button";


.send-notification-form {
  margin-bottom: 40px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.delete-notification {
  text-decoration: none;
}

.notification-form{
  display: flex;
  flex-direction: row;
  gap: 20px;
  
  @media screen and (max-width: $screen-md) {
    flex-direction: column;
  }

  .notification-form-left,
  .notification-form-right {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    >div{
      display: flex;
    }
  }

  .notification-form-left {
    .text-area{
      height: 150px;
      resize: none;
      font-family: $font-body;
      @include body-font;
    }
  }

  .notification-form-right{
    .image-input-wrapper{
      position: relative;
      width: 100%;
      height: 326px;
      overflow-y: auto;
      border: 2px dashed #d5d5d5;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: #888;
      font-size: 16px;
      text-align: center;

      .image-input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        padding: 10px;
        cursor: pointer;
      }

      .image-name {
        max-height: 100%;
        overflow: hidden;
        @include body-font;
      }

      .image-preview {
        position: absolute;
        right: 10px;
        height: 200px;
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        
        @media screen and (max-width: $screen-md) {
          height: 160px;
          width: 160px;
        }

        .preview-img{
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }

      .crop-preview{
        overflow: hidden;
        position: absolute;
        right: 10px;
        height: 520px;
        width: 550px;
        display: flex;
        align-items: center;
        justify-content: center;

        .crop-image{
          object-fit: contain;
          height: 512px;
          width: 512px;
        }
      }
    }
  }
}

.send-notification{
    button{
      padding: 10px;
      margin-top: 16px;
      border-radius: 5px;
      background-color: #0069a7;
      color: white;
      font-weight: 600;
      font-size: 16px;
      @media screen and (max-width: $screen-lg) {
        margin-bottom: 10px;
      }
      &:hover {
        background-color: #005387; 
      }
    }
  }

  .form-control {
    border: 1px solid $border-color;
    @include border-radius(5px);
    background-color: $white-color;
    flex-grow: 1;
    padding: 7.5px 30px 7.5px 10px;
    width: 100%;
    font-family: $font-body;
  }


  .send-notification-form input[type="text"] {
    margin-top: 0;
  }

  .dropdown {
    width: 100%;
  }

  .notifications-table {
    tr {
      th:first-child,
      td:first-child {
        text-align: left;
        padding-left: 35px;
        width: 15%;
        @media screen and (max-width: $screen-lg) {
          padding-left: 15px;
        }
  
        @media screen and (max-width: $screen-sm) {
          padding-left: 8px;
        }
      }
  
      th:last-child,
      td:last-child {
        width: 5%;
        text-align: center;
        @media screen and (min-width: $screen-xl) {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
  
      th:nth-child(2),
      td:nth-child(2) {
        text-align: left;
        word-break: break-word;
        width: 27%;
      }
  
      th:nth-child(3),
      td:nth-child(3) {
        text-align: center;
        word-break: break-word;
        width: 7%;
      }
  
      th:nth-child(4),
      td:nth-child(4) {
        text-align: center;
        word-break: break-word;
        width: 15%;
      }

      th:nth-child(6),
      td:nth-child(6) {
        text-align: center;
      }
  
      th:nth-child(7),
      td:nth-child(7) {
        text-align: center;
        word-break: break-word;
        width: 7%;
      }
  
      th:nth-child(8),
      td:nth-child(8) {
        text-align: center;
        word-break: break-word;
        width: 7%;
      }

      th:nth-child(9),
      td:nth-child(9) {
        text-align: center;
        word-break: break-word;
        width: 10%;
      }
    }
  }