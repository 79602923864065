@import "../common/variable";
@import "../common/mixins";

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

//////// Firefox ////////
input[type=number] {
    -moz-appearance: textfield;
    -ms-appearance: none;
    appearance: none;
}

.form-group {
    margin-bottom: 30px;

    .form-label {
        display: inline-block;
        font-size: 14px;
        color: $gray-3;
        margin-bottom: 5px;
        font-weight: 400;
    }

    .input-group {
        display: flex;
        position: relative;
        overflow: hidden;
        height: 50px;

        &.mobile {
            .form-control {
                padding-left: 66px;

                &.no-icon {
                    padding-left: 44px;
                }
            }

            .contry-code {
                pointer-events: none;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                height: 100%;
                width: 75px;
                margin-top: 1px;
                opacity: 0.7;
            }
        }

        @media screen and (max-width: $screen-sm) {
            height: 40px;

            &.mobile {
                .form-control {
                    padding-left: 68px;
                }

                .contry-code {
                    width: 65px
                }
            }
        }

        .input-icon {
            @include border-radius(5px 0px 0px 5px);
            background-color: #EBEBEB;
            border: 1px solid $border-color;
            font-size: 24px;
            width: 50px;
            height: 50px;
            position: absolute;
            left: 0px;
            top: 0px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media screen and (max-width: $screen-sm) {
                width: 40px;
                height: 40px;
                font-size: 18px;
            }
        }

        .form-control {
            border: 1px solid $border-color;
            @include border-radius(5px);
            background-color: $white-color;
            flex-grow: 1;
            padding: 14px 65px 13px;

            &:not([type="password"]) {
                padding-right: 15px;
            }

            @include body-font;
            color: $primary-color;
            appearance: none;
            width: 100%;
            max-width: 100%;

            &:disabled {
                color: $gray-1;
                border-color: $border-color;
            }

            @media screen and (max-width: $screen-sm) {
                padding: 12px 50px 11px;
            }

            &.no-icon {
                padding-left: 16px;

                & + .contry-code {
                    width: 44px;
                }
            }

            &:focus {
                border-color: $primary-color;
                color: $primary-color;

                & + .input-icon, & + .contry-code + .input-icon {
                    background-color: $primary-color;
                    border-color: $primary-color;
                    color: $white-color;
                }
            }

            &.error {
                border-color: $danger-color;
                color: $danger-color;

                & + .input-icon, & + .contry-code + .input-icon {
                    border-color: $danger-color;
                    background-color: $danger-color;
                    color: $white-color;
                }
            }

            &.success {
                border-color: $success-color;
                color: $success-color;

                & + .input-icon, & + .contry-code + .input-icon {
                    border-color: $success-color;
                    background-color: $success-color;
                    color: $white-color;
                }
            }
        }

        .show-pass {
            position: absolute;
            right: 0;
            top: 0;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $gray-1;
            cursor: pointer;

            i {
                line-height: 1;
            }

            @media screen and (max-width: $screen-sm) {
                width: 40px;
                height: 40px;
            }
        }
    }
}

.form-control {
    border: 1px solid $border-color;
    @include border-radius(5px);
    background-color: $white-color;
    flex-grow: 1;
    padding: 14px 12px;    
    @include body-font;
    color: $form-control-color;
    appearance: none;
    width: 100%;
    outline: none;
    max-width: 100%;

    &:not([type="password"]) {
        padding-right: 15px;
    }

    &.right-icon {
        padding-right: 40px;
    }

    &:disabled {
        color: $gray-1;
        border-color: $border-color;
    }

    @media screen and (max-width: $screen-sm) {
        padding: 12px;
    }

    &:focus {
        border-color: $primary-color;
        color: $primary-color;
    }

    &.error {
        border-color: $danger-color;
        color: $danger-color;
    }

    &.success {
        border-color: $success-color;
        color: $success-color;
    }
}

.error-info {
    font-size: 80%;
    line-height: 1.5;
    display: block;
    margin-top: 8px;
    color: $danger-color;
}