@import "../common/variable";
@import "../common/mixins";

.soclial-links {
    ul {
        list-style: none;
        display: flex;
        align-items: center;
        margin-bottom: 0;

        li {
            margin: 0;
            padding: 0 10px;
            display: inline-flex;

            &:before {
                content: none;
            }

            a {
                text-decoration: none;
                color: $white-color;
                display: inline-flex;
                line-height: 1;
                font-size: 18px;
            }
        }
    }
}

.care-list, .specialist-list, .card-list {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(4, 1fr);

    .order-last {
        order: 999;
    }

    @media screen and (max-width: $screen-lg) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: $screen-md) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: $screen-sm) {
        grid-template-columns: repeat(1, 1fr);
    }

    & > a {
        text-decoration: none;
        display: flex;
        align-items: center;
        padding: 30px;
        border: 1px solid $border-color;
        border-radius: 10px;
        background-color: $white-color;
        color: $gray-4;

        &:hover {
            background-color: $primary-color;
            color: $white-color;
            border-color: $primary-color;
        }
    }
}

.specialist-list {
    grid-template-columns: repeat(2, 1fr);

    @media screen and (max-width: $screen-lg) {
        margin-top: 30px;
        grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (max-width: $screen-md) {
        margin-bottom: 30px;
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: $screen-sm) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }

    @media screen and (max-width: $screen-xs) {
        grid-template-columns: 1fr;
    }

    a {
        padding: 14px 20px 13px;
    }

    & + .specialist-list-cta {
        .btn {
            @media screen and (max-width: $screen-xs) {
                width: 100%;
            }
        }
    }
}

.card-list {
    grid-template-columns: repeat(3, 1fr);

    @media screen and (max-width: $screen-xxl) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: $screen-md) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.pagination {
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;

    .pagination-item {
        display: inline-block;
        height: 50px;
        width: 50px;
        line-height: 54px;
        background-color: $gray-1;
        text-align: center;
        margin: 3px 0 0 10px;
        border-radius: 8px;
        font-weight: 600;
        color: $gray-3;
        cursor: pointer;

        &.active, &:hover {
            background-color: $primary-color;
            color: $white-color;
        }
    }
}