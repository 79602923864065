@import "../common/variable";
@import "../common/mixins";

.site-footer {
    background-color: $footer-bg;
    color: $white-color;
    padding: 40px 0;

    // position: sticky;
    // bottom: 0;
    z-index: 3;
    @media screen and (max-width: $screen-xs) {
        padding: 15px 0;
    }

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .copy-text {
            color: $gray-1;
            font-size: 14px;
            line-height: 18px;
            a {
                color: $white-color;
            }
        }

        @media screen and (max-width: $screen-xs) {
            flex-flow: column;
            align-items: center;

            .copy-text {
                margin-bottom: 15px;
            }
        }
    }
}