@import "../common/variable";
@import "../common/mixins";

.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $primary-color;
    border: 1px solid $primary-color;
    color: $white-color;
    border-radius: 5px;
    min-width: 140px;
    min-height: 44px;
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    padding: 10px 25px;
    text-decoration: none;
    cursor: pointer;

    &.outline {
        background-color: transparent;
        border-color: $primary-color;
        color: $primary-color;

        &:hover {
            background-color: $primary-color;
            color: $white-color;
        }
    }

    &:hover {
        color: $white-color;
        background-color: lighten($primary-color, 3);
    }

    @media screen and (max-width: $screen-sm) {
        min-height: 40px;
    }

    a {
        color: $white-color;
    }

    &.full {
        width: 100%;
    }

    .full {
        width: 100%;
    }

    &.btn-primary {
        background-color: $primary-color;
        border-color: $primary-color;
        color: $white-color;

         &:hover {
            background-color: lighten($primary-color, 3);
        }
    }

    &.btn-secondary {
        background-color: $white-color;
        border-color: $primary-color;
        color: $primary-color;

        &:hover {
            background-color: $primary-color;
            color: $white-color;
        }
    }

    &.btn-long-title {
        min-height: 40px;
        min-width: 164px;
    }

    &.success {
        background-color: $success-color;
        border-color: $success-color;

        &:hover {
            background-color: lighten($success-color, 3);
        }
        &.outline {
            background-color: transparent;
            border-color: $success-color;
            color: $success-color;
    
            &:hover {
                background-color: $success-color;
                color: $white-color;
            }
        }
    }

    &.warning {
        background-color: $warning-color;
        border-color: $warning-color;

        &:hover {
            background-color: lighten($warning-color, 3);
        }
        &.outline {
            background-color: transparent;
            border-color: $warning-color;
            color: $warning-color;
    
            &:hover {
                background-color: $warning-color;
                color: $white-color;
            }
        }
    }

    &.danger {
        background-color: $danger-color;
        border-color: $danger-color;

        &:hover {
            background-color: lighten($danger-color, 3);
        }
        &.outline {
            background-color: transparent;
            border-color: $danger-color;
            color: $danger-color;
    
            &:hover {
                background-color: $danger-color;
                color: $white-color;
            }
        }
    }

    &.sm {
        min-width: auto;
        min-height: auto;
        padding: 8px 15px;
        font-size: 14px;
    }

    &.loading {
        font-size: 0;
        pointer-events: none;

        &:before {
            content: "";
            height: 20px;
            width: 20px;
            border: 1px solid #fff;
            border-radius: 50%;
            animation: loading 1s both infinite;
            border-left: 0;
            display: inline-block;
        }

        &.outline {
            &::before {
                border-color: $primary-color;
            }
        }
    }

    &.btn-bold {
        font-weight: 600;
    }
}

.btn-small {
    padding: 12px 10px;
    border-radius: 5px;
    background-color: #0069a7;
    color: white;
    font-weight: 600;
    font-size: 16px;
    min-width: 120px;

    &:hover {
    background-color: #005387; 
    }
}

.btn-with-radius {
    background-color: #0069a7;
    color: white;
    padding: 16px 30px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 16px;
}

.btn-icon {
    border: 1px solid $primary-color;
    color: $primary-color;
    padding: 4px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;

    i {
        font-size: 16px;
    }
    
    &:hover {
        color: $white-color;
        background-color: $primary-color;
        background-color: lighten($primary-color, 3);
    }
}

@-webkit-keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}