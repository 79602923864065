@import "../common/variable";
@import "../common/mixins";
@import "../components/confirm-popup";
@import "../components/button";
@import "../components/popup";

.flex {
    display: flex;
    align-items: center; // Vertically center
    gap: 10px; // Adds space between elements
  }
  
  .justify-content-between {
    justify-content: space-between; // Distribute elements across the width
  }

.user-type {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 300px;
  position: relative;

  span {
    width: 35%;
  }
}

.status {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 300px;
  position: relative;

  span {
    width: 35%;
  }
}

.resource-document-table{
    tr {

      th {
        padding: 20px 16px;
      }
      th:first-child,
      td:first-child {
        text-align: left;
        padding-left: 35px;
        width: 25%;
        @media screen and (max-width: $screen-lg) {
          padding-left: 15px;
        }
  
        @media screen and (max-width: $screen-sm) {
          padding-left: 8px;
        }
      }
  
      th:nth-child(2),
      td:nth-child(2) {
        text-align: center;
        word-break: break-word;
        width: 10%;
      }
  
      th:nth-child(3),
      td:nth-child(3) {
        text-align: center;
        word-break: break-word;
        width: 7%;
      }
  
      th:nth-child(4),
      td:nth-child(4) {
        text-align: center;
        word-break: break-word;
        width: 8%;
      }

      th:nth-child(5),
      td:nth-child(5) {
        text-align: center;
        word-break: break-word;
        width: 10%;
      }

      th:nth-child(6),
      td:nth-child(6) {
        text-align: center;
      }

      th:nth-child(7),
      td:nth-child(7) {
        text-align: center;
      }

      th:last-child,
      td:last-child {
        width: 10%;
        text-align: center;
        @media screen and (min-width: $screen-xl) {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  }